@use 'theme';
@use 'styleHelpers';

.tagBar {
  @include styleHelpers.flex('row');
  overflow-x: auto;
  padding: theme.spacing(2, 0);
}

.tag {
  color: theme.color('accent_main');
  background-color: transparent;
  padding: theme.spacing(1, 2);
  margin-right: theme.spacing(1);
  border-radius: theme.roundness(1);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  user-select: none;
  text-transform: capitalize;
  white-space: nowrap;

  border: 1px solid theme.color('accent_main');

  transition: background-color theme.timing(1), color theme.timing(1);

  &:hover,
  &.tagSelected {
    background-color: theme.color('accent_main');
    color: theme.color('accent_contrastText')
  }
}
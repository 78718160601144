@use 'theme';
@use 'styleHelpers';

.clickableButton {
  @include styleHelpers.hideButton();
}

// Card Base
.cardBase {
  @include styleHelpers.hideLink();
  flex: 1;
  align-items: unset;
  // max-width: 90%;
}

.cardBaseImage {
  @include styleHelpers.card();
}

.cardBodyBase {
  @include styleHelpers.flex("column");
  @include styleHelpers.cardBody();
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
}

.tag {
  display: flex;
  color: #fff;
  background-color: theme.color("accent_main");
  padding: theme.spacing(0.5, 1);
  margin-bottom: theme.spacing(1);
}

.grow {
  flex: 1;
}

.byline {
  color: theme.color("textMuted");
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.author {
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spacer {
  // padding prints better than height for some reason
  padding-bottom: theme.spacing(2.5);
}

// Compact Card
.compactCard {
  @extend .cardBase;
  @include styleHelpers.flex("row");
}

.compactCardRelated {
  @extend .cardBase;
  @include styleHelpers.flex("column");
}

.compactCardImage {
  @extend .cardBaseImage;
  @include styleHelpers.lockWidth(40%);
}

.compactCardImageV2 {
  @extend .cardBaseImage;
  // @include styleHelpers.lockWidth(70%);
  // 327 x 218
}

.compactCardBody {
  @extend .cardBodyBase;
}

.compactCardBodyRelated {
  @extend .cardBodyBase;
  //@include styleHelpers.lockWidth(60%);
}

// Stacked Card
.stackedCard {
  @extend .cardBase;
  @include styleHelpers.flex("column");
  height: 100%;
}

.stackedCardImage {
  @extend .cardBaseImage;
}

.stackedCardBody {
  @include styleHelpers.flex("column");
  @include styleHelpers.cardBody();
  align-items: flex-start;
  overflow: hidden;
}

// Stacked Card Responsive
.stackedCardResponsive {
  @extend .cardBase;
  @include styleHelpers.flex("column");
  height: 100%;

  @include theme.mediaQuery("xs", "md") {
    flex-direction: row;
  }
}

// Image Card
.imageCard {
  @extend .cardBase;
  @include styleHelpers.card();
  border: none;
  height: 100%;
  @include styleHelpers.flex("column");
  align-items: flex-end;
  position: relative;
  @include styleHelpers.centerBackgroundImage();
  width: 100%;
}

.imageCardTitleWrap {
  @include styleHelpers.flex("column");
  justify-content: flex-end;
  @include styleHelpers.absoluteFill();
  padding: theme.spacing(2);
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
}

@use 'styleHelpers';
@use 'theme';

.hide {
  opacity: 0;
  pointer-events: none;
}

.slider {
  height: calc(25vw + 250px);
  background-color: #000;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid theme.color("divider");
}

.imageSlider {
  @extend .slider;
  height: calc(10vw + 250px);
}

.dots {
  display: flex;
  margin: theme.spacing(3);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
}

.arrows {
  position: absolute;
  //display: flex;
  //align-items: center;
  top: 50%;
  transform: translateY(-50%);
  //justify-content: space-between;
  //justify-items: space-between;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  //right: 95%;
  //display: block;
  left: 0;
  //right: 0;
  width: 50px;
  max-width: 50px;
  //float: left;
  //margin-right: auto;
}

.arrowRight {
  //float: right;
  //display: block;
  //left: 0;
  right: 0;
  position: absolute;
  width: 50px;
  max-width: 50px;
  top: 50%;
  //left: 95%;
  //margin-left: auto;
  transform: translateY(-50%);
}

.dot {
  height: 10px;
  width: 10px;
  margin: theme.spacing(0, 1);
  border-radius: 100%;
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
  cursor: pointer;
  background: transparent;
  padding: 0;
}

.dotActive {
  background-color: #fff;
}

.slide {
  @include styleHelpers.hideLink();
  @include styleHelpers.absoluteFill();
  @include styleHelpers.flex("column");
  justify-content: flex-end;
  transition: opacity theme.timing(10);

  &:focus img {
    outline-offset: -5px;
    outline-color: theme.color("accent_main");
    outline-color: -webkit-focus-ring-color;
    outline-width: 2px;
    outline-style: solid;
  }
}

.slideImage {
  @include styleHelpers.absoluteFill();
  position: absolute;
}

.slideCardImageOverlay {
  @include styleHelpers.absoluteFill();
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent);
}

.articleSlideCardImageOverlay {
  @include styleHelpers.absoluteFill();
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), transparent);
}

.slideCardTitleWrap {
  @include styleHelpers.flex("column");
  position: relative;
  margin-bottom: theme.spacing(3);
  padding: theme.spacing(2);
  width: 100%;
  border-left: 4px solid theme.color("accent_main");
  max-width: 600px;
  transition: opacity theme.timing(3);
}

.imageSlideCardTitleWrap {
  @include styleHelpers.flex("column");
  position: relative;
  //margin-bottom: theme.spacing(3);
  padding: theme.spacing(2);
  width: 100%;
  //border-left: 4px solid theme.color("accent_main");
  //max-width: 600px;
  transition: opacity theme.timing(3);
}

.byline {
  color: theme.color("textMuted");
}

.author {
  font-style: italic;
}

@use 'theme';
@use 'styleHelpers';

.backdrop {
  @include styleHelpers.absoluteFill();
  position: fixed;
  z-index: 2000;
  background-color: rgba(0,0,0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(40px) contrast(20%);
  opacity: 1;
  transition: opacity theme.timing(0.5);
  overscroll-behavior: contain;
}

.closeIcon {
  @include styleHelpers.hideButton();
  position: absolute;
  top: theme.spacing(2);
  right: theme.spacing(2);
  cursor: pointer;
  z-index: 2001;
  background-color: #fff;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  backdrop-filter: invert(100%) blur(50px) saturate(0%);
  color: #000;
}

.modal {
  background-color: theme.color('surface');
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  max-height: 100%;
  max-width: 100%;
  width: calc(750px + 30vw);
  overflow: auto;
}

.hide {
  opacity: 0;
  pointer-events: none;
}
@use 'theme';
@use 'styleHelpers';

.page {
  @include styleHelpers.flex("column");
  background-color: theme.color("background_light");
  min-height: 100%;
}

@media print {
  .printable {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    flex: 1;

    * {
      visibility: visible;
      height: auto;
    }
  }
}
